import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PostLayout from '../components/Layout/PostLayout';
import PostTemplateDetails from '../components/pages/PostTemplateDetails';
import { SideMenu } from '../components/SideMenu';

type IProps = {
  data: any;
};

const PostTemplate: React.FC<IProps> = (props) => {
  const { title, subtitle } = props.data.site.siteMetadata;
  const post = props.data.markdownRemark;
  const { title: postTitle, description: postDescription } = post.frontmatter;
  const description = postDescription !== null ? postDescription : subtitle;

  return (
    <PostLayout>
      <div className="columns">
        <SideMenu />
        <div className="column is-9-tablet is-9-desktop is-9-widescreen">
          <Helmet>
            <title>{`${postTitle} - ${title}`}</title>
            <meta name="description" content={description} />
          </Helmet>
          <PostTemplateDetails {...props} />
        </div>
      </div>
    </PostLayout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          twitter
        }
        disqusShortname
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        tags
        date
        description
      }
    }
  }
`;
