import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

type IProps = {
  tag: string;
  name: string;
};

export const BlockTag: React.FC<IProps> = ({ tag, name }) => {
  return (
    <TagItem key={tag}>
      <TagLink to={tag} className="post-single__tags-list-item-link">
        {name}
      </TagLink>
    </TagItem>
  );
};

const TagItem = styled.li`
  display: inline-block;
  margin: 10px 5px;
`;

const TagLink = styled(Link)`
  background: #ebebeb;
  text-decoration: none;
  border: 0;
  border-radius: 3px;
  color: #555555;
  line-height: 1.625;
  padding: 8px 16px;
  &:hover,
  &:focus {
    color: #222;
    background: #dfdfdf;
    border: 0;
  }
`;
