import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import { Card, CardContent } from '../common/Card';
import styled from 'styled-components';

export const TagsComponent: React.FC = () => {
  const {
    tags: { group: tags },
  } = useStaticQuery(
    graphql`
      query {
        tags: allMarkdownRemark(
          limit: 2000
          filter: {
            frontmatter: { layout: { eq: "post" }, draft: { ne: true } }
          }
        ) {
          group(field: frontmatter___tags) {
            fieldValue
            totalCount
          }
        }
      }
    `
  );

  return (
    <Card>
      <CardContent>
        <Title>タグ</Title>
        <Tags>
          {tags.map((tag: { fieldValue: string; totalCount: number }) => {
            return (
              <div key={tag.fieldValue}>
                <TagLink to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  <Tag>{tag.fieldValue}</Tag>
                  <TagCount>{tag.totalCount}</TagCount>
                </TagLink>
              </div>
            );
          })}
        </Tags>
      </CardContent>
    </Card>
  );
};

const Title = styled.h3`
  line-height: normal;
  margin-top: 0;
`;

const Tags = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  > div:not(:last-child) {
    margin: 0 6px 6px 0;
  }
`;

const TagLink = styled(Link)`
  > span:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  > span:not(:first-child) {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const Tag = styled.span`
  align-items: center;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  color: #4a4a4a;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding: 0 8px;
  white-space: nowrap;
`;

const TagCount = styled.span`
  align-items: center;
  background-color: whitesmoke;
  border-radius: 0.25rem;
  color: #4a4a4a;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding: 0 8px;
  white-space: nowrap;
`;
