import React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import { Categories } from './Categories';
import { TagsComponent } from './Tags';

export const SideMenu: React.FC<unknown> = () => {
  return (
    <Container className="column is-3-tablet">
      {/*<div className="card widget" id="toc">*/}
      {/*  <div className="card-content">*/}
      {/*    <div className="menu">*/}
      {/*      <h3 className="menu-label">*/}
      {/*        カタログ*/}
      {/*      </h3>*/}
      {/*      <ul className="menu-list">*/}
      {/*        <li>*/}
      {/*          <a className="is-flex" href="#中身をのぞいて見る">*/}
      {/*            <span className="has-mr-6">1</span>*/}
      {/*            <span>中身をのぞいて見る</span>*/}
      {/*          </a>*/}
      {/*          <ul className="menu-list">*/}
      {/*            <li>*/}
      {/*              <a className="is-flex" href="#List-lt-T-Indexer">*/}
      {/*                <span className="has-mr-6">1.1</span>*/}
      {/*                <span>List&lt;T&gt;Indexer</span>*/}
      {/*              </a></li>*/}
      {/*          </ul>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a className="is-flex" href="#総まとめ">*/}
      {/*            <span className="has-mr-6">2</span>*/}
      {/*            <span>総まとめ</span>*/}
      {/*          </a></li>*/}
      {/*        <li>*/}
      {/*          <a className="is-flex" href="#感想">*/}
      {/*            <span className="has-mr-6">3</span>*/}
      {/*            <span>感想</span>*/}
      {/*          </a></li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Categories />
      <TagsComponent />
      {/*<div className="card widget">*/}
      {/*  <div className="card-content">*/}
      {/*    <div className="menu">*/}
      {/*      <h3 className="menu-label">*/}
      {/*        アーカイブ*/}
      {/*      </h3>*/}
      {/*      <ul className="menu-list">*/}
      {/*        <li>*/}
      {/*          <a className="level is-marginless" href="/archives/2020/08/">*/}
      {/*            <span className="level-start">*/}
      {/*              <span className="level-item">8月 2020</span>*/}
      {/*            </span>*/}
      {/*            <span className="level-end">*/}
      {/*              <span className="level-item tag">2</span>*/}
      {/*            </span>*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <a className="level is-marginless" href="/archives/2019/06/">*/}
      {/*            <span className="level-start">*/}
      {/*              <span className="level-item">6月 2019</span>*/}
      {/*            </span>*/}
      {/*            <span className="level-end">*/}
      {/*              <span className="level-item tag">4</span>*/}
      {/*            </span>*/}
      {/*          </a>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="column-right-shadow is-hidden-widescreen"></div>
    </Container>
  );
};

const Container = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;
