import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Card, CardContent } from '../common/Card';
import { BlockTag } from '../common/BlockTag';

type IProps = {
  data: any;
};

const PostTemplateDetails: React.FC<IProps> = (props) => {
  const { subtitle, author } = props.data.site.siteMetadata;
  const post = props.data.markdownRemark;
  const tags = post.fields.tagSlugs;

  return (
    <Card>
      <CardContent>
        <PostTitle>{post.frontmatter.title}</PostTitle>
        <PostBody
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <PostFooter>
          <PostDate>
            <em>
              Published {moment(post.frontmatter.date).format('D MMM YYYY')}
            </em>
          </PostDate>
          <Tags>
            <TagsList>
              {tags &&
                tags.map((tag: string, i: number) => (
                  <BlockTag tag={tag} name={post.frontmatter.tags[i]} />
                ))}
            </TagsList>
          </Tags>
        </PostFooter>
      </CardContent>
    </Card>
  );
};

const PostTitle = styled.h1`
  font-size: 38px;
  margin: 20px auto;
  font-weight: 600;
  text-align: center;
`;

const PostBody = styled.div`
  padding: 0 24px;
  ul {
    margin-left: 32px;
  }
`;

const PostFooter = styled.div`
  margin: 40px 0 20px 0;
  padding: 0 16px;
`;

const Tags = styled.div`
  margin: 0 20px;
`;

const TagsList = styled.ul`
  list-style: none;
  margin: 0 -0.3125rem;
  padding: 0;
`;

const PostDate = styled.div`
  margin: 16px auto;
`;

export default PostTemplateDetails;
